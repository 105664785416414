import { Container, Grid, Typography } from "@mui/material";

// type Props = { name: string };

function SubmittedForm() {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Typography variant="h3" align="center">
            Cпасибо за обращение!
          </Typography>
          <Typography variant="h5" align="center">
            Мы свяжемся с Вами в ближайшее время
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SubmittedForm;
