import {
  Container,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Snackbar,
  Checkbox,
  Box,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import Loading from "./Loading";

interface FeedbackData {
  firstName: string;
  contactInfo: string;
  question: string;
  contactType: string;
}

type Props = { onSubmit: Function };

function FeedbackForm({ onSubmit }: Props) {
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("+48");
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [contactMethod, setContactMethod] = useState<string>("phone");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);

  const isPhoneDisplay = (method: string) => {
    return method === "phone" || method === "viber" || method === "whatsapp";
  };

  const SubmitFeedback = (feedbackData: FeedbackData) => {
    const url: string = process.env.REACT_APP_BACKEND_URL + "ContactUs";
    setIsLoading(true);
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(feedbackData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => setIsLoading(false));
  };

  const handleSubmit = async () => {
    const errors: string[] = [];
    if (name.trim() === "") {
      errors.push("Имя не может быть пустым");
    }
    if (phoneNumber.trim() === "" || phoneNumber.trim() === "+48") {
      errors.push("Номер телефона не может быть пустым");
    }

    if (errors.length === 0) {
      const feedbackData: FeedbackData = {
        firstName: name,
        contactInfo: phoneNumber,
        question: feedbackText,
        contactType: contactMethod,
      };

      await SubmitFeedback(feedbackData);
      onSubmit();
      setName("");
      setPhoneNumber("");
      setFeedbackText("");
      setContactMethod("phone");
      setSnackbarOpen(true);
    } else {
      setErrors(errors);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Box pb={5}>
      <Container component={"form"} maxWidth="sm">
        <Typography variant="h5" margin={5} align={"center"}>
          Заполните форму для обратной связи
        </Typography>
        <Typography variant="h6" align={"center"}>
          Мы ответим в ближайшее время.
        </Typography>
        <TextField
          label="Ваше Имя"
          required
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={errors.includes("Имя не может быть пустым")}
          helperText={
            errors.includes("Имя не может быть пустым") && "Введите ваше имя"
          }
          margin="normal"
        />
        {isPhoneDisplay(contactMethod) && (
          <MuiTelInput
            label="Номер телефона"
            required
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e)}
            margin="normal"
            error={errors.includes("Номер телефона не может быть пустым")}
            helperText={
              errors.includes("Номер телефона не может быть пустым") &&
              "Введите номер телефона"
            }
          />
        )}
        {contactMethod === "telegram" && (
          <TextField
            label="Telegram Id / Номер телефона"
            required
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            margin="normal"
            error={errors.includes("Номер телефона не может быть пустым")}
            helperText={
              errors.includes("Номер телефона не может быть пустым") &&
              "Введите Telegram Id / Номер телефона"
            }
          />
        )}
        {contactMethod === "email" && (
          <TextField
            label="Email"
            required
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            margin="normal"
            error={errors.includes("Номер телефона не может быть пустым")}
            helperText={
              errors.includes("Номер телефона не может быть пустым") &&
              "Введите Email"
            }
          />
        )}
        <TextField
          label="Ваш вопрос"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          margin="normal"
        />
        <FormControl component="fieldset" margin="normal" required>
          <Typography variant="subtitle1" gutterBottom>
            Предпочтительный способ связи
          </Typography>
          <RadioGroup
            row
            aria-label="contact-method"
            name="contact-method"
            value={contactMethod}
            onChange={(e) => {
              setContactMethod(e.target.value);
              if (isPhoneDisplay(e.target.value)) {
                setPhoneNumber("+48");
              } else {
                setPhoneNumber("");
              }
            }}
          >
            <FormControlLabel
              value="phone"
              control={<Radio />}
              label="Телефон"
            />
            <FormControlLabel
              value="telegram"
              control={<Radio />}
              label="Telegram"
            />
            <FormControlLabel value="viber" control={<Radio />} label="Viber" />
            <FormControlLabel
              value="whatsapp"
              control={<Radio />}
              label="WhatsApp"
            />
            <FormControlLabel value="email" control={<Radio />} label="Email" />
          </RadioGroup>
          <FormControlLabel
            required
            control={
              <Checkbox
                value={isAccepted}
                onChange={(e) => setIsAccepted(Boolean(e.target.checked))}
              />
            }
            label="Я соглашаюсь на обработку моих персональных данных."
          />
        </FormControl>
        <Button
          disabled={!isAccepted}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Отправить
        </Button>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Отзыв отправлен"
        />
      </Container>
    </Box>
  );
}

export default FeedbackForm;
