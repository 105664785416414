import { useState } from "react";
import FeedbackForm from "./FeedbackForm";
import SubmittedForm from "./SubmittedForm";

function ContactForm() {
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  return (
    <div>
      {!isFormSubmitted ? (
        <FeedbackForm onSubmit={() => setIsFormSubmitted(true)} />
      ) : (
        <SubmittedForm />
      )}
    </div>
  );
}

export default ContactForm;
