import { Box, Button, Container, Stack, Typography } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

function ContactInfoForm() {
  return (
    <Box pb={5}>
      <Container component={"form"} maxWidth="sm">
        <Typography variant="h6" margin={5} align={"center"}>
          Для связи с нами выберите один из способов:
        </Typography>
        <Stack spacing={2}>
          <Button
            style={{
              fontSize: 20,
            }}
            startIcon={<TelegramIcon />}
            variant="contained"
            href="https://t.me/ProDokument_pl"
          >
            Telegram
          </Button>
          <Button
            style={{
              fontSize: 20,
            }}
            startIcon={<PhoneInTalkIcon />}
            variant="contained"
            href="viber://chat/?number=%2B48694994881"
          >
            Viber
          </Button>
          <Button
            style={{
              fontSize: 20,
            }}
            startIcon={<WhatsAppIcon />}
            variant="contained"
            href="https://wa.me/48694994881"
          >
            Whats App
          </Button>
          <Button
            style={{
              fontSize: 20,
            }}
            startIcon={<EmailIcon />}
            variant="contained"
            href="mailto:info@prodokument.pl"
          >
            Email
          </Button>
          <Button
            style={{
              fontSize: 20,
            }}
            startIcon={<PhoneIcon />}
            variant="contained"
            href="tel:+48694994881"
          >
            Телефон
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}

export default ContactInfoForm;
