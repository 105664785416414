import { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactForm from "./components/FeedbackForm";
import ContactInfoForm from "./components/ContactInfoForm/ContactInfoForm";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#332885",
    },
  },
  typography: {
    fontFamily: ["Comfortaa", "Arial"].join(","),
    fontSize: 15,
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<ContactInfoForm />} />
          <Route path="/contact-form" element={<ContactForm />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
